


.BJJ_AuthModalLink {
    font-size: 14px;
    color: #00a64d;
    cursor: pointer;
    display: inline-block;
    margin-left: auto;

}

.BJJ_AuthModalLinkRemember {
    margin-top: 1rem;
    position: absolute;
    bottom: 2rem;
    right: 40px;
}


